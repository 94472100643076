import { Link } from "react-router-dom"
import "./InformationContentStyles.css"

import React from 'react'

import Perfil from "../assets/perfil.png"
import IntroImg from "../assets/background/mulher-em-sessao-de-fisioterapia.jpg"
import { FaRegClock, FaWhatsapp, FaTimesCircle, FaPills } from "react-icons/fa"
import { GoChecklist } from "react-icons/go"
import { PiClockCountdownFill } from "react-icons/pi"


const InformationContent = () => {
    return (
        <section id="informations">
            <div className="information">
                {/* <div className="information-mask">
                <img className="information-img" src={IntroImg} alt="InformationImg" />
            </div> */}

                <h1>Informações sobre a consulta</h1>

                <div className="content">
                    <p>
                        <FaRegClock size={40} style={{ marginRight: "1rem" }} color="#0c488d" />Atendimento com duração aproximada de <b>60 minutos</b>.
                    </p>
                    <p>
                        <PiClockCountdownFill size={48} style={{ marginRight: "1rem" }} color="#0c488d" />Tolerância máxima de atraso é 15 minutos.
                    </p>
                    <p>
                        <FaTimesCircle size={40} style={{ marginRight: "1rem" }} color="#0c488d" />Não atendemos crianças menores de 12 anos.
                    </p>
                    <p>
                        <GoChecklist size={40} style={{ marginRight: "1rem" }} color="#0c488d" />Será definido a quantidade inicial de atendimentos fisioterapêuticos necessários para recuperação, sempre levando em consideração a evolução do paciente.
                    </p>
                    <p>
                        <FaPills size={40} style={{ marginRight: "1rem" }} color="#0c488d" />Levar exames e medicações em uso.
                    </p>

                    <div className="agendar">
                        <a href="https://api.whatsapp.com/send/?phone=5534998680500&text=Ol%C3%A1!%20Quero%20agendar%20uma%20avalia%C3%A7%C3%A3o,%20voc%C3%AA%20pode%20me%20passar%20mais%20informa%C3%A7%C3%B5es?" key="WhatsApp" target="_blank" rel="noopener noreferrer" underline="none">
                            <button className="btn"><FaWhatsapp size={20} style={{ marginRight: "1rem" }} />Agendar avaliação!</button>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InformationContent