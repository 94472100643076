import "./HomeContentStyles.css"

import React from 'react'

import LogoImg from "../assets/logo.png"
import { Link } from "react-router-dom"
import Background from "./Background"

const HeroImg = () => {
    return (
        <div id="home" className="home">
            <Background />
            <div className="content">
                <img className="logo-img" src={LogoImg} alt="LogoImg" />
                <h1>Dra. Ana Cláudia Carrijo</h1>
                <p>FISIOTERAPEUTA</p>
                {/* <div>
                    <Link to="#about" className="btn">Sobre</Link>
                    <Link to="/contact" className="btn btn-light">Contato</Link>
                </div> */}
            </div>
        </div>
    )
}

export default HeroImg