import { FaHome, FaInstagram, FaLinkedin, FaMailBulk, FaPhone, FaWhatsapp } from "react-icons/fa"
import "./ContactStyles.css"

import React from 'react'
import Background from "./Background"

const Contact = () => {
    return (
        <section id="contact">
            <div className="contact">

                <h1>Contatos</h1>

                <div className="contact-container">
                    <div className="left">
                        <p>
                            <a href="https://goo.gl/maps/qRwjSPKfmWZzbCAf8" key="GoogleMaps" target="_blank" rel="noopener noreferrer" underline="none">
                                <FaHome size={30} style={{ marginRight: "1rem" }} color="#0c488d" />
                                <b>Volver ProFisio</b>
                                <p></p>
                                Av. Afonso Pena, 4669 - Bairro Umuarama - Uberlândia/MG
                            </a>
                        </p>

                        <p>
                            <a href="tel:034998680500" key="Telefone" target="_blank" rel="noopener noreferrer" underline="none">
                                <FaPhone size={30} style={{ marginRight: "1rem" }} color="#0c488d" /> (34) 99868-0500
                            </a>
                        </p>

                        <p>
                            <FaMailBulk size={30} style={{ marginRight: "1rem" }} color="#0c488d" /> anaccarrijo.fst@gmail.com
                        </p>
                    </div>
                    <div className="right">
                        <p>
                            <a href="https://api.whatsapp.com/send/?phone=5534998680500&text=Ol%C3%A1!%20Quero%20agendar%20uma%20avalia%C3%A7%C3%A3o,%20voc%C3%AA%20pode%20me%20passar%20mais%20informa%C3%A7%C3%B5es?" key="WhatsApp" target="_blank" rel="noopener noreferrer" underline="none">
                                <FaWhatsapp size={30} style={{ marginRight: "1rem" }} color="#0c488d" /> +5534998680500
                            </a>
                        </p>

                        <p>
                            <a href="https://www.instagram.com/anaclaudia.fst" key="Instagram" target="_blank" rel="noopener noreferrer" underline="none">
                                <FaInstagram size={30} style={{ marginRight: "1rem" }} color="#0c488d" /> @anaclaudia.fst
                            </a>
                        </p>

                        <p>
                            <a href="https://linkedin.com/in/anaclaudiacarrijo" key="Instagram" target="_blank" rel="noopener noreferrer" underline="none">
                                <FaLinkedin size={30} style={{ marginRight: "1rem" }} color="#0c488d" /> anaclaudiacarrijo
                            </a>
                        </p>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact