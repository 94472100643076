import React from "react";
import "./index.css"
import HomeContent from "./components/HomeContent"
import AboutContent from "./components/AboutContent"
import InformationContent from "./components/InformationContent"
import ContactContent from "./components/ContactContent";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";

function App() {
  return (
    <div className="App">
      <Navbar />
      <HomeContent />
      <AboutContent />
      <InformationContent />
      <ContactContent />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
