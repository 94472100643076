import "./BackgroundStyles.css"

import React from 'react'

import BackgroundImg from "../assets/background/mulher-jovem-com-problemas-nas-costas-fazendo-tratamento-de-fisioterapia.jpg"

const Background = () => {
    return (
        <div className="mask-background">
            <img className="background-img" src={BackgroundImg} alt="Background" />
        </div>
    )
}

export default Background