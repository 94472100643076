import "./NavbarStyles.css"

import React, { useState } from 'react'

import Icon from "../assets/draAnaLogo.png"

const Navbar = () => {
    const [activeNav, setActiveNav] = useState('home');
    const [click, setClick] = useState(false)
    const [color, setColor] = useState(false)

    const changeColor = () => {
        if (window.scrollY >= 1) {
            setColor(true)
        } else {
            setColor(false)
        }
    };

    const scrollToId = (id) => {
        var section = document.getElementById(id);
        section.scrollIntoView({ behavior: 'smooth' }, true);
    }

    const handleOnClick = (id) => {
        setActiveNav(id)
        scrollToId(id)
    }

    window.addEventListener("scroll", changeColor)

    return (
        <>
            <div className={color ? "nav nav-bg" : "nav"}>
                <a href="/">
                    <img className="logo" src={Icon} alt="Logo" width={120} height={78} />
                </a>

                <ul id="navbar" className={click ? "#navbar active" : "#navbar"}>
                    <li>
                        <button
                            onClick={() => handleOnClick('home')}
                            className={activeNav === 'home' ? 'active' : ''}
                        >
                            Início
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => handleOnClick('about')}
                            className={activeNav === 'about' ? 'active' : ''}
                        >
                            Sobre
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => handleOnClick('informations')}
                            className={activeNav === 'informations' ? 'active' : ''}
                        >
                            Informações
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => handleOnClick('contact')}
                            className={activeNav === 'contact' ? 'active' : ''}
                        >
                            Contato
                        </button>
                    </li>
                </ul>

                <div id="mobile" onClick={() => setClick(click => !click)}>
                    <i id="bar" className={click ? "fas fa-times" : "fas fa-bars"}></i>
                </div>
            </div>

        </>
    )


}

export default Navbar

// const Navbar = () => {
//     const [activeNav, setActiveNav] = useState('#');
//     const [click, setClick] = useState(false)
//     const handleClick = () => setClick(!click)

//     const [color, setColor] = useState(false)
//     const changeColor = () => {
//         if (window.scrollY >= 1) {
//             setColor(true)
//         } else {
//             setColor(false)
//         }
//     };

//     window.addEventListener("scroll", changeColor)

//     return (
//         <div className={color ? "header header-bg" : "header"}>
//             <Link to="/">
//                 <img className="logo" src={Icon} alt="Logo" width={120} height={78} />
//             </Link>
//             <ul className={click ? "nav-menu active" : "nav-menu"}>
//                 <nav className="navegacao">
//                     <button href="#"
//                         onClick={() => setActiveNav('#')}
//                         className={activeNav === '#' ? 'active' : ''}
//                     >
//                         <Typography>
//                             Home
//                         </Typography>
//                     </button>
//                     <button href="#about"
//                         onClick={() => setActiveNav('#about')}
//                         className={activeNav === '#about' ? 'active' : ''}
//                     >
//                         <Typography>
//                             Sobre
//                         </Typography>
//                     </button>
//                     <button href="#informations"
//                         onClick={() => setActiveNav('#informations')}
//                         className={activeNav === '#informations' ? 'active' : ''}
//                     >
//                         <Typography>
//                             Informações
//                         </Typography>
//                     </button>
//                     <button href="#contact"
//                         onClick={() => setActiveNav('#contact')}
//                         className={activeNav === '#contact' ? 'active' : ''}
//                     >
//                         <Typography>
//                             Contato
//                         </Typography>
//                     </button>
//                 </nav>
//             </ul>
//             <div className="hamburguer" onClick={handleClick}>
//                 {
//                     click ? (<FaTimes size={20} style={{ color: "#333333" }} />)
//                         : (<FaBars size={20} style={{ color: "#333333" }} />)
//                 }
//             </div>
//         </div>
//     )
// }

// export default Navbar